import * as React from 'react'
import { ToggleLeft, ToggleRight } from '@phosphor-icons/react'

type ToggleButtonProps = {
  checked: boolean
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

export const ToggleButton: React.VFC<ToggleButtonProps> = (props) => {
  const { onChange, checked } = props

  const FilterIcon = checked ? ToggleRight : ToggleLeft

  return (
    <label className="cursor-pointer text-sm text-gray-900 flex flex-row items-center gap-x-1 mb-0 select-none whitespace-nowrap">
      <input
        checked={checked}
        onChange={onChange}
        type="checkbox"
        readOnly
        className="sr-only"
      />
      <FilterIcon
        aria-hidden
        size={28}
        className="text-theme-50 mt-0.5"
        weight={checked ? 'fill' : 'bold'}
      />
    </label>
  )
}
